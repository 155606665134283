html {
    background: #485563;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #29323c, #485563);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #29323c, #485563);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.work-box {
    column-gap: 3px;
    max-width: 60%;
}

.marked {
    /*     cursor: pointer; */
    overflow: hidden;
    padding: 0px 10px;
    transition: 0.3s all;
    position: relative;
    background: orange;
    /*     padding: 0 3px; */
    border-radius: 4px;
    color: black;
}

.tag-block {
    margin: 20px 0;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tag-button {
    background: black;
    cursor: pointer;
}

.tag-button:hover {
    background: rgba(50, 50, 50, 1);
}

.tag-button-active {
    background: orange !important;
    color: black;
}

.marked-name {
    display: flex;
    position: relative;
    /*     width: 100%; */
    /*     left: 0; */
    /*     right: 0; */
    font-weight: bold;
    align-content: center;
    font-size: 10px;
    color: black;
    top: 0;
    padding: 2px;
    margin-left: 2px;
    background: black;
    color: white;
}

.class-words {
    position: relative;
    margin: 10px 0 50px 0;
    margin-top: 30px;
    gap: 3px;
    display: flex;
    float: clear;
    flex-wrap: wrap;
}

.type {
    cursor: pointer;
    border: 1px solid black;
    /* color: orange; */
    color: black;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 5px;
    position: relative;
    background-color: #111111;
}

.index-class {
    font-size: 10px;
    position: absolute;
    right: 10%;
    bottom: 5%;
}

.selected-type {
    border: 1px solid orange;
    box-shadow: inset 0 -5px 10px -5px orange, inset 0 5px 10px -5px orange, 0 0 3px 1px orange;
}

.main-box {
    /* margin-top: 100px; */
    min-width: 400px;
    position: relative;
    padding: 20px;
    background: #485563;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #29323c, #485563);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #29323c, #485563);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.word-box {
    align-items: center;
    display: flex;
    /* padding: 0 10px; */
    margin-bottom: 5px;
    font-size: 18px;
}

.words {
    border: 1px solid black;
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    background: rgba(0, 0, 0, 0.149);
    border-radius: 0 10px 10px 10px;
    padding: 20px 10px;
    margin-bottom: 40px;
    position: relative;
}

.words ::selection {
    color: orange;
    background: black;
}

.marked:hover .close-marked {
    opacity: 1;
    right: -1px;
    transition: 0.3s all !important;
    /*     display: block !important; */
}

.marked:hover {
    padding-right: 17px;
    padding-left: 3px;
}

.close-marked:hover {
    color: red;
}

.close-marked {
    cursor: pointer;
    opacity: 1;
    transition: 0.3s all;
    border-radius: 5px;
    position: absolute;
    background: black;
    color: orange;
    top: 50%;
    display: flex;
    transform: translateY(-50%);
    font-weight: bold;
    padding: 0px 4px;
    font-size: 13px;
    right: -100px;
    align-items: center;
    z-index: 1000;
    height: 110%;
    line-height: 7px;
    /*     border-radius: 50%; */
}

.App {
    display: flex;
    min-height: 100vh;
    justify-content: space-between;
    align-items: center;
}

.App>* {
    /*         border: 1px solid black; */
    /* min-width: 100px; */
    /* min-height: 100vh; */
    padding: 10px;
    gap: 30px;
    margin-top: -100px;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
}

.action-button {
    transition: 0.2s all;
    padding: 10px 15px;
    border: 1px solid orange;
    border-radius: 10px;
    cursor: pointer;
}

.action-button:hover {
    background: black;
    color: orange;
}

.current-level {
    position: absolute;
    right: 10px;
    top: 10px;
    color: orange;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 20px;
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.sidebar {
    opacity: 0;
    background: black;
    min-width: 100px;
    border-radius: 0 10px 10px 0;
    box-shadow: 0 0 5px black;
    min-height: 400px;
}

.export {
    top: 0;
    position: relative;
    padding: 10px 15px;
    box-shadow: 0 0 4px black;
    border: 1px solid black;
    border-top: none;
    border-radius: 0 0 0px 10px;
    margin: 0 !important;
    position: fixed;
    right: 0px;
    z-index: 0;
    cursor: pointer;
    background: black;
    color: orange;
}

.import {
    top: 0;
    padding: 10px 15px;
    box-shadow: 0 0 4px black;
    border: 1px solid black;
    border-top: none;
    border-radius: 0 0 10px 0px;
    margin: 0 !important;
    position: fixed;
    left: 0px;
    z-index: 0;
    cursor: pointer;
    background: black;
    color: orange;
}

.export:hover,
.import:hover {
    color: black !important;
    background-color: orange;
}

.current-level {
    white-space: nowrap;
}

.completed {
    display: inline-block;
    color: black;
}

.disabled-button {
    cursor: not-allowed;
    border-color: gray;
    color: gray;
}

.disabled-button:hover {
    background: gray;
    border-color: lightgray;
    color: gray;
}

#validation-box {
    position: absolute;
    left: 50%;
    bottom: 0;
    display: flex;
    padding: 0px;
    transform: translateX(-50%);
}

#validation-box>* {
    position: relative;
    min-width: 70px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    margin: 0 10px;
    min-height: 70px;
    cursor: pointer;
}

.accept:hover,
.deny:hover {
    filter: invert(0.4);
}

.accept {
    border-radius: 5px 5px 0 0;
    border: 4px solid transparent;
    border-bottom: none;
    color: white;
    background: black !important;
}

.accept::before,
.deny::before {
    position: absolute;
    left: 50%;
    bottom: 110%;
    transform: translateX(-50%);
    opacity: 0.1;
    font-size: 10px;
    color: white;
}

.accept::before {
    content: "press z";
}

.deny::before {
    content: "press x";
}

.deny {
    border-radius: 5px 5px 0 0;
    border: 4px solid transparent;
    border-bottom: none;
    color: white;
    background: black !important;
}

.next,
.prev {
    position: relative;
}

.next.disabled-button::before,
.prev.disabled-button::before {
    opacity: 0;
    transition: 0.3s all;
}

.save {
    display: none;
}

.next::before,
.prev::before {
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.1;
    font-size: 10px;
    white-space: nowrap;
}

.next::before {
    content: "press Space";
}

.prev::before {
    content: "press LCtrl";
}

.continue-from-last {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.question {
    text-align: center;
}

.question-responses {
    display: flex;
    gap: 10px;
}

.question-responses>* {
    padding: 10px 20px;
    border: 1px solid orange;
    border-radius: 10px;
    cursor: pointer;
}

.question-responses>*:hover {
    color: orange;
    background: black;
    border-color: black;
}

.active-accept {
    border: 4px solid orange;
    border-bottom: none;
}

.active-deny {
    border: 4px solid orange;
    border-bottom: none;
}

.too-small {
    position: fixed;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1000;
    right: 0;
    color: orange;
    bottom: 0;
    top: 0;
    background: black;
}

.clasification-block {
    display: flex;
    /*     gap: 10px; */
    align-items: center;
}

.clasification {
    position: relative;
    cursor: pointer;
    font-variant: small-caps;
    transition: 1s all;
    padding: 0px 10px;
    gap: 5px;
    margin-left: 5px;
    border-radius: 10px;
    box-shadow: inset 0 0px 0px 0px orange;
}

.suggestion-clasifier {
    position: absolute;
    bottom: 115%;
    opacity: 0.1;
    color: white;
    transform: translateX(-50%);
    left: 50%;
    font-size: 10px;
    text-transform: lowercase !important;
    font-variant: normal;
}

.active-clasfier {
    /*     background: orange; */
    /*     color: black; */
    box-shadow: inset 0 10px 10px -10px rgba(255, 132, 0, 0.631), inset 0 -10px 10px -10px rgba(255, 132, 0, 0.631);
}

.clasification:hover {
    color: black;
}

.adno-name {
    position: absolute;
    right: 100%;
    background: black;
    top: 0;
    outline-color: black !important;
    border-color: black;
    border-radius: 0 0 0 10px;
    padding: 5px;
    border: none;
    color: orange;
    outline: none !important;
    box-shadow: 0 0 3px black;
}

.speaker {
    position: absolute;
    bottom: 100%;
    font-size: 15px;
    left: -1px;
    /* transform: translateX(-50%); */
    padding: 5px;
    background: black;
    color: white;
    font-weight: bold;
    border-radius: 10px 10px 0px 0px;
}